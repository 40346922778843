import React from 'react'
import { Box,Text,Button,Container } from "@chakra-ui/react";
import Header from './Components/Header/Header';
import Hero from './Components/Hero';
import Features from './Components/Features';
import Partner from './Components/Partner';
import Footer from './Components/Footer';
import CTA from './Components/CallToAction';

const App = () => {
  return (
    <Container maxW='container.xl'>
       <Header />
      <Hero />
      <Features />
      <Partner />
      <CTA />
      <Footer />
    </Container>
  );
}
 
export default App;